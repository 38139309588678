import { createReducer, on } from '@ngrx/store';
import * as configurationActions from './configuration.actions';
import { filter, isEqual, isNil, omit } from 'lodash-es';
import { ConfigurationStatus } from '@wap/configuration';
import { ConfigurationManagementState } from '@wam/shared';

const initialState: ConfigurationManagementState = {
  configurations: [],
  searchTerm: '',
  lastEvaluated: undefined,
  isEndOfList: false,
  pending: false,
  definitions: [],
};

const configurationReducer = createReducer(
  initialState,
  on(configurationActions.load, (state) => ({
    ...state,
    pending: true,
  })),
  on(configurationActions.loadSuccess, (state, { items }) => ({
    ...state,
    configurations: [...state.configurations, ...items],
    pending: false,
  })),
  on(configurationActions.loadFail, (state) => ({
    ...state,
    pending: false,
  })),
  on(configurationActions.updateLastEvaluated, (state, { lastEvaluated }) => ({
    ...state,
    lastEvaluated,
    isEndOfList: isNil(lastEvaluated),
  })),
  on(configurationActions.update, (state, { item }) => ({
    ...state,
    configurations: state.configurations.map((current) =>
      isEqual(omit(current, ['status', 'value']), omit(item, ['status', 'value']))
        ? { ...item }
        : current,
    ),
  })),
  on(configurationActions.resetConfiguration, (state, { item }) => ({
    ...state,
    configurations: state.configurations.map((current) =>
      isEqual(omit(current, 'status'), omit(item, 'status'))
        ? { ...current, status: oppositeStatus(current.status) }
        : current,
    ),
  })),
  on(configurationActions.createSuccess, (state, { item }) => ({
    ...state,
    configurations: [],
    lastEvaluated: undefined,
    isEndOfList: false,
  })),
  on(configurationActions.deleteConfigurationSuccess, (state, { item }) => ({
    ...state,
    configurations: filter(state.configurations, (config) => !isEqual(config, item)),
  })),
  on(configurationActions.updateSearchTerm, (state, { searchTerm }) => ({
    ...state,
    searchTerm,
    configurations: [],
    lastEvaluated: undefined,
    isEndOfList: false,
  })),
  on(configurationActions.loadDefinitionsSuccess, (state, { definitions }) => ({
    ...state,
    definitions,
  })),
  on(configurationActions.deleteDefinitionSuccess, (state, { key }) => ({
    ...state,
    definitions: state.definitions.filter((definition) => definition.key !== key),
    configurations: state.configurations.filter((configuration) => configuration.key !== key),
  })),
);

export function oppositeStatus(status: ConfigurationStatus): ConfigurationStatus {
  return status === ConfigurationStatus.ENABLED
    ? ConfigurationStatus.DISABLED
    : ConfigurationStatus.ENABLED;
}

export function reducer(state, action) {
  return configurationReducer(state, action);
}
