import { createAction, props } from '@ngrx/store';
import { IConfigurationItem, IConfigurationItemKeys, IDefinition } from '@wap/configuration';

export const load = createAction(
  '[Configuration Component] Load Configurations',
  props<{ page?: number; limit?: number }>(),
);

export const loadSuccess = createAction(
  '[Configuration Management API] Load Configurations Success',
  props<{ items: IConfigurationItem[] }>(),
);

export const loadFail = createAction('[Configuration Management API] Load Configurations Fail');

export const updateLastEvaluated = createAction(
  '[Configuration Management API] Update Last Evaluated',
  props<{ lastEvaluated: IConfigurationItemKeys }>(),
);

export const update = createAction(
  '[Configuration Component] Update Configuration',
  props<{ item: IConfigurationItem }>(),
);

export const updateSuccess = createAction(
  '[Configuration Management API] Update Configuration Success',
);

export const updateFail = createAction(
  '[Configuration Management API] Update Configuration Fail',
  props<{ error: string; item: IConfigurationItem }>(),
);

export const resetConfiguration = createAction(
  '[Configuration Side Effect] Reset Configuration',
  props<{ item: IConfigurationItem }>(),
);

export const create = createAction(
  '[Configuration Component] Create Configuration',
  props<{ item: IConfigurationItem }>(),
);

export const createSuccess = createAction(
  '[Configuration Management API] Create Configuration Success',
  props<{ item: IConfigurationItem }>(),
);

export const createFail = createAction(
  '[Configuration Management API] Create Configuration Fail',
  props<{ error: string }>(),
);

export const deleteConfiguration = createAction(
  '[Configuration Component] Delete Configuration',
  props<{ item: IConfigurationItem }>(),
);

export const deleteConfigurationSuccess = createAction(
  '[Configuration Component] Delete Configuration Success',
  props<{ item: IConfigurationItem }>(),
);

export const deleteConfigurationFail = createAction(
  '[Configuration Component] Delete Configuration Fail',
  props<{ error: string }>(),
);

export const updateSearchTerm = createAction(
  '[Configuration Component] Update Search Term',
  props<{ searchTerm: string }>(),
);

export const loadDefinitions = createAction(
  '[Create Configuration Component] Load Configuration Definitions',
);

export const loadDefinitionsSuccess = createAction(
  '[Configuration Management API] Load Configuration Definitions Success',
  props<{ definitions: IDefinition[] }>(),
);

export const loadDefinitionsFail = createAction(
  '[Configuration Management API] Load Configuration Definitions Fail',
  props<{ error: string }>(),
);

export const createOrUpdateDefinition = createAction(
  '[Create Configuration Component] Create Or Update Definition',
  props<{ definition: IDefinition }>(),
);

export const createOrUpdateDefinitionSuccess = createAction(
  '[Configuration Management API] Create Or Update Definition Success',
);

export const createOrUpdateDefinitionFail = createAction(
  '[Configuration Management API] Create Or Update Definition Fail',
  props<{ error: string }>(),
);

export const deleteDefinition = createAction(
  '[Delete Definition Dialog] Delete Definition',
  props<{ key: string }>(),
);

export const deleteDefinitionSuccess = createAction(
  '[Delete Definition Dialog] Delete Definition Success',
  props<{ key: string }>(),
);

export const deleteDefinitionFail = createAction(
  '[Delete Definition Dialog] Delete Definition Fail',
  props<{ error: string }>(),
);
